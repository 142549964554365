import React from 'react';
import { graphql, useStaticQuery } from "gatsby";

const gatsbyQuery = graphql`
  query AudioPlayerQuery {
    allPerformanceRecording {
      nodes {
        id
        performanceKey
        source
        childrenAudioFile {
          url
        }
        song {
          title
          slug
          id
        }

        performance {
          date
          slug
          location {
            name
          }
        }
      }
    }
  }
`;
export const useStaticPerformanceRecordings = () => {
  return useStaticQuery(gatsbyQuery).allPerformanceRecording.nodes;
};

export const usePerformanceRecording = (id: string | null) => {
  const recordings = useStaticPerformanceRecordings();
  if (!id) {
    return null;
  }
  return recordings.find((node) => node.id === id);
}
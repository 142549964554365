import React from "react";
import { NowPlayingProps } from "../lib";
import {
  Link,
  Typography,
  AppBar,
  Toolbar,
  makeStyles,
} from "@material-ui/core";
import WithTheme, { drawerWidth } from "./WithTheme";

import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import SkipNextIcon from "@material-ui/icons/SkipNext";

const useStyles = makeStyles((theme) => ({
  bottomBar: {
    top: "auto",
    bottom: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  nowPlayingToolbar: {
    display: "grid",
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "1fr 1fr 0fr",
    },
    gridTemplateColumns: "1fr 1fr 1fr",
  },

  metadata: {
    display: "flex",
    flexFlow: "column nowrap",
  },

  metadataLink: {
    color: "inherit",
    textDecoration: "none",
  },

  controls: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const NowPlayingToolbar = ({
  onPlay,
  onPause,
  onPrev,
  onNext,
  isPlaying,
  currentRecording,
}: NowPlayingProps) => {
  const classes = useStyles();
  if (currentRecording) {
    return (
      <Toolbar className={classes.nowPlayingToolbar}>
        <div className={classes.metadata}>
          <Typography variant="subtitle2">
            <Link
              className={classes.metadataLink}
              href={currentRecording.song.slug}
            >
              {currentRecording.song.title}
            </Link>
          </Typography>
          <Typography variant="caption">
            <Link
              className={classes.metadataLink}
              href={currentRecording.performance.slug}
            >
              {currentRecording.performance.date}{" "}
              {currentRecording.performance.location.name}
            </Link>
          </Typography>
        </div>
        <div className={classes.controls}>
          <SkipPreviousIcon
            onClick={onPrev}
            style={{ cursor: "pointer", fontSize: 30 }}
          />
          {isPlaying ? (
            <PauseCircleFilledIcon
              onClick={onPause}
              style={{ cursor: "pointer", fontSize: 50 }}
            />
          ) : (
            <PlayCircleFilledIcon
              onClick={onPlay}
              style={{ cursor: "pointer", fontSize: 50 }}
            />
          )}
          <SkipNextIcon
            onClick={onNext}
            style={{ cursor: "pointer", fontSize: 30 }}
          />
        </div>
        <div />
      </Toolbar>
    );
  } else {
    return <div />;
  }
};

export default (props: NowPlayingProps) => {
  const classes = useStyles();
  return (
    <WithTheme>
      <AppBar position="fixed" className={classes.bottomBar}>
        <NowPlayingToolbar {...props} />
      </AppBar>
    </WithTheme>
  );
};

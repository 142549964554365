import React, { useReducer } from "react";

export type PlaylistState = {
  id: null | string;
  collection: null | string;
  playlist: string[];
  isPlaying: boolean;
};

type PlaylistAction =
  | { type: "UPDATE"; data: Partial<PlaylistState> }
  | { type: "CHECK_PLAYLIST"; playlist: string[] }
  | { type: "NEXT" }
  | { type: "PREV" }
  | { type: "PLAY"; id?: string, playlist?: string[] }
  | { type: "PAUSE" };

export type PlaylistDispatch = React.Dispatch<PlaylistAction>;

export const TrackContext = React.createContext<{
  state: PlaylistState;
  dispatch: React.Dispatch<PlaylistAction>;
}>({
  state: {
    id: null,
    collection: null,
    playlist: [],
    isPlaying: false,
  },
  dispatch: () => {},
});

function playlistReducer(
  state: PlaylistState,
  action: PlaylistAction
): PlaylistState {
  const currentIndex = (state.playlist || []).findIndex(
    (id) => id === state.id
  );

  switch (action.type) {
    case "UPDATE":
      return { ...state, ...action.data };
    case "PREV":
      const prevIndex =
        currentIndex - 1 === -1 ? state.playlist.length - 1 : currentIndex - 1;
      return { ...state, id: state.playlist[prevIndex] };
    case "NEXT":
      const nextIndex =
        currentIndex + 1 === state.playlist.length ? 0 : currentIndex + 1;
      return { ...state, id: state.playlist[nextIndex] };
    case "PLAY":
      return { ...state, isPlaying: true, id: action.id || state.id, playlist: action.playlist || state.playlist };
    case "PAUSE":
      return { ...state, isPlaying: false };
    case "CHECK_PLAYLIST":
      if(state.id && !action.playlist.includes(state.id)){
        return state;
      }
      return {
        ...state,
        playlist: action.playlist,
      };
    default:
      throw new Error("Unhandled action type");
  }
}

export default ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(playlistReducer, {
    id: null,
    collection: null,
    isPlaying: false,
    playlist: [],
  });

  return (
    <TrackContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </TrackContext.Provider>
  );
};

import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core";

const primaryColor = "#f1f1f1";
export const drawerWidth = 240;

export const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: "#448aff",
    },
  },
});

export default ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

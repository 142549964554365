import React, { useContext } from "react";
import { TrackContext } from "./AudioPlayerProvider";
import ReactPlayer from "react-player";
import NowPlaying from "./NowPlaying";
import { usePerformanceRecording } from "../lib/useStaticPerformanceRecordings";

export default () => {
  const { state, dispatch } = useContext(TrackContext);

  const performanceRecording = usePerformanceRecording(state.id);

  if (!state.id) {
    return <div />;
  }

  return (
    <>
      <NowPlaying
        onPlay={() => dispatch({ type: "PLAY" })}
        onPause={() => dispatch({ type: "PAUSE" })}
        isPlaying={state.isPlaying}
        currentRecording={performanceRecording}
        onPrev={() => dispatch({ type: "PREV" })}
        onNext={() => dispatch({ type: "NEXT" })}
      />
      <ReactPlayer
        onEnded={() => dispatch({ type: "NEXT" })}
        width={0}
        height={0}
        playing={state.isPlaying}
        url={performanceRecording.childrenAudioFile[0].url}
      />
    </>
  );
};

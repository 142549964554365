import React, { useState } from "react";

import AudioPlayer from "../components/AudioPlayer";
// TODO: Maybe helmet is not the best solution for this
import { Helmet } from "react-helmet";
import AudioPlayerProvider from "./AudioPlayerProvider";

export default ({ element }) => {
  return (
    <AudioPlayerProvider>
      <Helmet>
        <meta name="viewport" content="initial-scale=1" />
      </Helmet>
      {element}
      <AudioPlayer />
    </AudioPlayerProvider>
  );
};

// /**
//  * Implement Gatsby's Browser APIs in this file.
//  *
//  * See: https://www.gatsbyjs.org/docs/browser-apis/
//  */

// // You can delete this file if you're not using it
import React from "react";
import GlobalLayout from "./src/components/GlobalLayout";
export const wrapRootElement = (props) => <GlobalLayout {...props} />;

// import GlobalLayout from "./src/components/GlobalLayout"
// export const wrapRootElement = GlobalLayout
